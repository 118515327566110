/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import { useState, useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Button, Container, IconButton } from '@mui/material';
// redux
import { useSnackbar } from 'notistack';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import axiosInstance from '../../../utils/axios';
// import AccordionUsage from './Accordian';

// ----------------------------------------------------------------------

export default function ReportListing() {
  const { enqueueSnackbar } = useSnackbar();
  const [Data, setdata] = useState([]);
  const [loader, setloader] = useState(false);

  const GetAllAdmin = () => {
    setloader(true);
    axiosInstance
      .get('/all-listing-reports')
      .then((response) => {
        if (response.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setloader(false);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 5,
      },
      {
        accessorKey: 'name',
        header: 'Reporter Name',
        size: 10,
        Cell: ({ row }) => <p>{row.original?.user?.first_name}</p>,
      },
      {
        accessorKey: 'Lisitng By',
        header: 'Listing By',
        size: 10,
        Cell: ({ row }) => <p>{row.original?.listing?.host?.first_name}</p>,
      },
      {
        accessorKey: 'message',
        header: 'Message',
        size: 10,
      },
      {
        accessorKey: 'timestamp',
        header: 'TimeStamp',
        size: 10,
      },
      {
        accessorKey: 'date',
        header: 'Date',
        size: 10,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  const data = [
    {
      id: 1,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 1,
    },
    {
      id: 2,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 1,
    },
    {
      id: 3,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 1,
    },
    {
      id: 4,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 1,
    },
    {
      id: 5,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 0,
    },
  ];

  useEffect(() => {
    setTableData(data);
  }, []);

  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.delete(`/listing-report/${rowdata}`).then((response) => {
        console.log(response);
        if (response?.data?.message) {
          enqueueSnackbar(response?.data?.message);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  async function handleApproved(id, hi) {
    try {
      //  console.log(user?.id);
      await axiosInstance.get(`/subadmin-status-change/${id}`).then((response) => {
        if (response) {
          console.log(response?.data, '=========');
          enqueueSnackbar(response?.data?.message);
          // reset();
          // navigate(PATH_DASHBOARD.dorm.dorm);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }

  return (
    <>
      <Page title="Report Listing">
        <Container maxWidth="lg">
          <HeaderBreadcrumbs heading="Report Listing" links={[{ name: '', href: '' }]} action={<></>} />

          <MaterialReactTable
            columns={columns}
            enableRowActions
            data={Data}
            positionActionsColumn="last"
            state={{ showSkeletons: loader }}
            renderRowActions={({ row }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: '10px',
                  justifyContent: 'flex-center',
                }}
              >
                <IconButton
                  color="error"
                  sx={{
                    border: '1px solid',
                    borderColor: 'error.main',
                  }}
                  onClick={() => {
                    handleDelete(row.original.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          />
        </Container>
      </Page>
      {/* <AccordionUsage/> */}
    </>
  );
}
