/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useMemo, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { Card, Grid, Stack, Container, Box, Typography, Input, TextField } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import axiosInstance from '../../../utils/axios';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

const pages = [
  { name: 'Home Page', key: 'home', imageKey: 'image_one', linkKey: 'link_one' },
  { name: 'Rooms for Rent', key: 'rooms', imageKey: 'image_two', linkKey: 'link_two' },
  { name: 'Apartments for Rent', key: 'apartments', imageKey: 'image_three', linkKey: 'link_three' },
  { name: 'Houses for Rent', key: 'houses', imageKey: 'image_four', linkKey: 'link_four' },
  { name: 'Search Pages', key: 'search', imageKey: 'image_five', linkKey: 'link_five' },
  { name: 'View Profile', key: 'profile', imageKey: 'image_six', linkKey: 'link_six' },
  { name: 'Logout', key: 'logout', imageKey: 'image_seven', linkKey: 'link_seven' },
];

export default function AddBannerImage() {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const GetAllAdmin = async () => {
    try {
      const response = await axiosInstance.get('/image-setting');
      if (response.data.data) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetAllAdmin();
  }, []);

  const validationSchema = Yup.object().shape({
    images: Yup.array().of(Yup.mixed().required('Image is required')).min(1, 'At least one image is required'),
    links: Yup.array().of(Yup.string().url('Invalid URL')).min(1, 'At least one link is required'),
  });

  const defaultValues = useMemo(() => {
    const values = {};
    pages.forEach((page) => {
      values[`${page.imageKey}`] = null;
      values[`${page.linkKey}`] = data?.[0]?.[`${page.linkKey}`] || '';
    });
    return values;
  }, [data]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
    control,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = async (formData) => {
    try {
      const fd = new FormData();
      pages.forEach((page) => {
        const file = formData[`${page.imageKey}`];
        if (file) {
          fd.append(`${page.imageKey}`, file);
        }
        const link = formData[`${page.linkKey}`];
        if (link) {
          fd.append(`${page.linkKey}`, link);
        }
      });

      const response = await axiosInstance.post('/image-setting', fd);
      if (response.data?.message) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        reset();
        navigate('/dashboard/banner-image');
      }
    } catch (error) {
      enqueueSnackbar(error?.message || 'An error occurred', { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs
        heading="Edit Banner Images"
        links={[{ name: 'Banner Images', href: '/dashboard/banner-images' }, { name: 'Edit Banner Images' }]}
      />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            {pages.map((page) => (
              <Grid item xs={12} key={page.key}>
                <Card sx={{ p: 3 }} style={{ boxShadow: 'none' }}>
                  <Stack>
                    <Box sx={{ mb: -3 }}>
                      <Typography variant="subtitle2">{page.name}</Typography>
                      {/* Display existing image if available */}
                      {data.length > 0 && data[0][page.imageKey] && (
                        <img
                          src={data[0][page.imageKey]}
                          alt={`${page.name} image`}
                          style={{ width: '100%', height: '200px', borderRadius: '15px', marginBottom: '10px' }}
                        />
                      )}
                      <Controller
                        name={`${page.imageKey}`}
                        control={control}
                        render={() => (
                          <Input
                            style={{ width: '100%' }}
                            type="file"
                            inputProps={{ accept: 'image/*' }}
                            onChange={(e) => {
                              setValue(`${page.imageKey}`, e.target.files[0]);
                            }}
                          />
                        )}
                      />
                      <Controller
                        name={`${page.linkKey}`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            sx={{ mt: 5 }}
                            {...field}
                            label={`Link for ${page.name}`}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        )}
                      />
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            ))}
            <Grid item xs={12}>
              <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                Edit Banner Images
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Container>
  );
}
