export const RentPeriod = [
    { name: "Daily" },
    { name: "Weekly" },
    { name: "1-to-6 months" },
    { name: "12 months" },
    { name: "12-24 months" },
    { name: "24-36 months" },
  ];

  export const Availability = [
    {
      name: "In a week",
    },
    {
      name: "In a month",
    },
    {
      name: "In two months",
    },
    {
      name: "In three months",
    },
    {
      name: "In six months",
    },
    {
      name: "In a year",
    },
  ];
