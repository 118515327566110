/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router';
import { Card, Grid, Stack, Container, Box, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import { fData } from '../../../utils/formatNumber';
import axiosInstance from '../../../utils/axios';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

export default function AddUser() {
  // const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const NewSubSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    wp_hone: Yup.string()
      .matches(/^233\d{9}$/, 'Phone number must start with 233 and be exactly 12 digits long')
      .required('Phone number is required'),
    password: Yup.string().required('Password is required'),
    file: Yup.mixed().required('Image is required'),
  });

  const { state } = useLocation();
  const defaultValues = useMemo(
    () => ({
      first_name: state?.first_name || '',
      last_name: state?.last_name || '',
      email: state?.email || '',
      // phone: '',
      wp_hone: state?.phone || '233',
      password: '',
      file: state?.image || '',
    }),
    [state]
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const navigate = useNavigate();
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('first_name', data.first_name);
      formData.append('last_name', data.last_name);
      formData.append('email', data.email);
      formData.append('password', data.password);
      formData.append('phone', data.wp_hone);
      formData.append('image', data.file);
      const response = await axiosInstance.post('/user', formData);
      if (response.data?.message) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        reset();
        navigate('/dashboard/users');
      }
    } catch (error) {
      enqueueSnackbar(error?.message || 'An error occurred', { variant: 'error' });
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'file',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  // const formatPhoneNumber = (value) => {
  //   if (!value) return '';
  //   // Remove non-numeric characters
  //   const cleaned = value.replace(/\D+/g, '');
  //   // Add hyphen formatting
  //   const match = cleaned.match(/^(\d{0,4})(\d{0,3})(\d{0,3})$/);
  //   if (match) {
  //     return `${match[1]}-${match[2]}-${match[3]}`;
  //   }
  //   return value;
  // };

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs
        heading="Add User"
        links={[{ name: 'Users', href: '/dashboard/users' }, { name: 'Add User' }]}
      />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Host Profile Image
                    </Typography>
                    <RHFUploadAvatar
                      name="file"
                      accept="image/*"
                      maxSize={3145728}
                      onDrop={handleDrop}
                      helperText={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png, *.gif
                          <br /> max size of {fData(3145728)}
                        </Typography>
                      }
                    />
                  </Box>
                  <RHFTextField name="first_name" label="First Name" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="last_name" label="Last Name" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="email" type="email" label="Email" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="password" type="password" label="Password" InputLabelProps={{ shrink: true }} />
                  {/* <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <RHFTextField
                        {...field}
                        label="Phone Number"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          const formattedValue = formatPhoneNumber(e.target.value);
                          setValue('phone', formattedValue);
                        }}
                        value={formatPhoneNumber(field.value)}
                        inputProps={{ maxLength: 12 }} // Limit the input length
                      />
                    )}
                  /> */}
                  <Controller
                    name="wp_hone"
                    control={control}
                    render={({ field }) => (
                      <RHFTextField
                        {...field}
                        label="Phone Number"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          maxLength: 12, // Limit the total input length to 12 characters
                        }}
                        onChange={(e) => {
                          let value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                          if (!value.startsWith('233')) {
                            // eslint-disable-next-line prefer-template
                            value = '233' + value.slice(3); // Ensure the value always starts with '233'
                          }
                          if (value.length > 12) {
                            value = value.slice(0, 12); // Ensure the value does not exceed 12 characters
                          }
                          setValue('wp_hone', value); // Update the value
                        }}
                        value={field.value}
                      />
                    )}
                  />

                  <Grid item xs={12}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Add User
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
