/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Container,
  Box,
  Typography,
  MenuItem,
  Select,
  Divider,
  // Radio,
  //   CardHeader,
  Grid,
  Card,
  CardContent,
  Stack,
  Tooltip,
  IconButton,
} from '@mui/material'; // Import Typography for text
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

import axiosInstance from '../../../utils/axios';
import { useLocation, useParams } from 'react-router';

function ViewHost() {
  const { id } = useParams();
  const { state } = useLocation();
  // const [data, setData] = useState([]);
  // const GetData = () => {
  //   axiosInstance
  //     .get(`/listing/${id}`)
  //     .then((res) => {
  //       if (res?.data?.status === true) {
  //         setData(res?.data?.listing);
  //       }
  //       console.log(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // useEffect(() => {
  //   GetData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Page title="View Host">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={state?.front_id ? `View Verification` : 'Not Found'}
          links={[{ name: '', href: '' }]}
          action={<></>}
        />
        {state?.front_id ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card sx={{ my: 3 }}>
                <CardContent>
                  <Stack spacing={2}>
                    <Stack direction="row" justifyContent="space-between">
                      <img src={state?.front_id} />
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ my: 3 }}>
                <CardContent>
                  <Stack spacing={2}>
                    <Stack direction="row" justifyContent="space-between">
                      <img src={state?.selfie} />
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ my: 3 }}>
                <CardContent>
                  <Stack spacing={2}>
                    <Stack direction="row" justifyContent="space-between">
                      <img src={state?.back_id} />
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </Container>
    </Page>
  );
}

export default ViewHost;
