/* eslint-disable no-unused-expressions */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, Box, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
// import BaseUrl from '../../BaseUrl/BaseUrl';
import { fData } from '../../../utils/formatNumber';
// import { BlogSchema } from '../AllSchema/Blog';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import 'react-quill/dist/quill.snow.css';
import axiosInstance from '../../../utils/axios';
import { PATH_DASHBOARD } from '../../../routes/paths';

export default function AddAbout() {
  const [data, setdataone] = useState([]);
  const Website = () => {
    axiosInstance
      .get(`/about`)
      .then((response) => {
        if (response.data.status === true) {
          setdataone(response.data.data);
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    Website();
  }, []);
  const BlogSchema = Yup.object().shape({
    text_one: Yup.string().required('Presenting Your Property is required'),
    text_two: Yup.string().required('Renting or Selling is required'),
    text_three: Yup.string().required('Property Exchange is required'),
    text_four: Yup.string().required('Buying a Property is required'),
    image: Yup.mixed().required(' image is required'),
    //   description: Yup.string().required('Description is required'),
  });
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loader] = useState(false);
  const [value, setValue] = useState('');
  // const [, setImageUrls] = useState([]);
  const defaultValues = useMemo(
    () => ({
      text_one: data?.text_one || '',
      text_two: data?.text_two || '',
      text_three: data?.text_three || '',
      text_four: data?.text_four || '',
      text_five: data?.text_five || '',
      image: data?.image || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const methods = useForm({
    resolver: yupResolver(BlogSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    setValue: setFormValue,
    formState: { isSubmitting },
  } = methods;
  // Reset form when data changes
  useEffect(() => {
    reset(defaultValues);
    setValue(data?.text_five);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, reset]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setFormValue(
          'image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setFormValue]
  );

  const values = watch();

  const OnSubmit = async () => {
    try {
      // console.log(values, 'values-->>');
      const formData = new FormData();

      formData.append('text_one', values?.text_one);
      formData.append('text_two', values?.text_two);
      formData.append('text_three', values?.text_three);
      formData.append('text_four', values?.text_four);
      formData.append('text_five', value);
      if (typeof data?.image === 'string') {
        formData.append('image', '');
      } else if (typeof data?.image === 'object') {
        formData.append('image', data?.image);
      }

      axiosInstance.post('/about', formData).then((response) => {
        if (response?.data?.status === true) {
          enqueueSnackbar('Added Successfully');
          reset();
          navigate(PATH_DASHBOARD.company.aboutus);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };

  const quillRef = useRef(null);

  // const imageHandler = useCallback(() => {
  //   const input = document.createElement('input');
  //   input.setAttribute('type', 'file');
  //   input.setAttribute('accept', 'image/*');
  //   input.setAttribute('multiple', 'true');
  //   input.click();

  //   input.onchange = async () => {
  //     setLoader(true);
  //     const files = Array.from(input.files);
  //     if (files.length > 0) {
  //       const editor = quillRef.current.getEditor();
  //       const range = editor.getSelection();
  //       editor.blur();
  //       const temp = await localStorage.getItem('accessToken');
  //       const config = {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //           Authorization: ` Bearer ${temp}`,
  //         },
  //       };
  //       const uploadPromises = files.map((file) => {
  //         const formData = new FormData();
  //         formData.append('des_image', file);
  //         return axios
  //           .post('https://api.dg.dev-iuh.xyz/api/subadmin/blogImage', formData, config)
  //           .then((result) => result?.data?.des_image)
  //           .catch((error) => {
  //             setLoader(false);
  //             console.error('Error:', error);
  //             return null;
  //           });
  //       });

  //       const urls = await Promise.all(uploadPromises);
  //       urls.forEach((url) => {
  //         if (url) {
  //           setImageUrls((prevUrls) => [...prevUrls, url]);
  //           editor.insertEmbed(range.index, 'image', url);
  //           editor.setSelection(range.index + 1); // Move cursor to the right of the inserted image
  //         }
  //       });

  //       editor.focus();
  //       setLoader(false);
  //       // Restore focus after insertion
  //     }
  //   };
  // }, []);

  return (
    <Container maxWidth="lg">
      <HeaderBreadcrumbs heading="Add About" links={[{ name: '', href: '' }]} />
      <FormProvider methods={methods} onSubmit={handleSubmit(OnSubmit)}>
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                  About Image
                </Typography>
                <RHFUploadAvatar
                  name="image"
                  accept="image/*"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />
              </Box>
              <Stack spacing={3} sx={{ py: 3 }}>
                <RHFTextField name="text_one" label="Presenting Your Property" />
                <RHFTextField name="text_two" label="Renting or Selling" />
                <RHFTextField name="text_three" label="Property Exchange" />
                <RHFTextField name="text_four" label="Buying a Property" />

                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  ref={quillRef}
                  modules={{
                    toolbar: {
                      container: [
                        [{ header: '1' }, { header: '2' }, { font: [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                        ['link', 'image', 'video'],
                        ['clean'],
                      ],
                      // handlers: {
                      //   image: imageHandler,
                      // },
                    },
                  }}
                  formats={[
                    'header',
                    'font',
                    'size',
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'blockquote',
                    'list',
                    'bullet',
                    'indent',
                    'link',
                    'image',
                    'video',
                  ]}
                />
              </Stack>
              <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting || loader}>
                Create About
              </LoadingButton>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
}
