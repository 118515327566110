/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { useEffect, useMemo, useState } from 'react';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import toast, { Toaster } from 'react-hot-toast';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// import { Button, Grid } from '@mui/material';
import { Button, Grid, Box, Tooltip, IconButton } from '@mui/material';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import { PATH_DASHBOARD } from '../../../routes/paths';
import axiosInstance from '../../../utils/axios';

export default function Faq() {
  const [dataone, setdataone] = useState([]);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    navigate(PATH_DASHBOARD.Support.addfaq);
  };

  const Website = () => {
    axiosInstance
      .get(`/faq`)
      .then((response) => {
        if (response.data.status === true) {
          setdataone(response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
        console.error(error);
      });
  };
  useEffect(() => {
    Website();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'id',
        size: 10,
      },

      {
        accessorKey: 'question',
        header: 'Question',
        size: 10,
      },
      {
        accessorKey: 'answer',
        header: 'Answer',
        size: 10,
      },
      {
        accessorKey: 'image',
        header: 'Image',
        size: 20,
        Cell: ({ row }) => (
          // console.log(`${url}/storage/${row?.original?.image}`)
          // eslint-disable-next-line react/jsx-no-target-blank
          <img src={`${row?.original?.image}`} alt="Hello" />
        ),
      },
      {
        accessorKey: 'action',
        header: 'Action',
        size: 100,
        Cell: ({ row }) => {
          // eslint-disable-next-line prefer-destructuring
          const id = row.original.id;
          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => handleEdit(row.original, id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handledelete(id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handledelete = async (id) => {
    await axiosInstance
      .delete(`/faq/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Deleted Succesfully');
        }
        Website();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // ---------------handlesubmit--------------------

  // ----------------HandleUpdate-----------------------
  const handleEdit = (data, id) => {
    navigate(`/dashboard/editfaq/${id}`, { state: data });
  };

  return (
    <>
      <Grid container>
        <Toaster position="top-right" reverseOrder={false} />
        <Grid item xs={12} md={12}>
          <HeaderBreadcrumbs
            heading="FAQ"
            links={[{ name: '', href: '' }]}
            action={
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
                FAQ
              </Button>
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <MaterialReactTable columns={columns} data={dataone} />
        </Grid>
      </Grid>
    </>
  );
}
