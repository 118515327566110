/* eslint-disable no-unused-expressions */
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container } from '@mui/material';
import ReactQuill from 'react-quill';
// import BaseUrl from '../../BaseUrl/BaseUrl';
// import { BlogSchema } from '../AllSchema/Blog';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import 'react-quill/dist/quill.snow.css';
import axiosInstance from '../../../utils/axios';

export default function OurContact() {
  // const BlogSchema = Yup.object().shape({
  //   title: Yup.string().required('Title is required'),
  //   image: Yup.mixed().required('Blog Banner image is required'),
  //     description: Yup.string().required('Description is required'),
  // });
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState('');
  const Website = () => {
    axiosInstance
      .get(`/content`)
      .then((response) => {
        if (response.data.status === true) {
          setValue(response.data.data?.career);
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    Website();
  }, []);

  const onSubmit = async () => {
    setLoader(true);
    try {
      const dorm = new FormData();

      dorm.append(`career`, value);

      await axiosInstance.post(`/content`, dorm).then((response) => {
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
          setLoader(false);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      setLoader(false);
    }
  };
  const quillRef = useRef(null);

  return (
    <Container maxWidth="lg">
      <HeaderBreadcrumbs heading="Add Careers" links={[{ name: '', href: '' }]} />
      <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3} sx={{ py: 3 }}>
              <ReactQuill
                theme="snow"
                value={value}
                onChange={setValue}
                ref={quillRef}
                modules={{
                  toolbar: {
                    container: [
                      [{ header: '1' }, { header: '2' }, { font: [] }],
                      [{ size: [] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                      ['link', 'image', 'video'],
                      ['clean'],
                    ],
                  },
                }}
                formats={[
                  'header',
                  'font',
                  'size',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'link',
                  'image',
                  'video',
                ]}
              />
            </Stack>
            <LoadingButton type="submit" variant="contained" size="large" loading={loader} onClick={onSubmit}>
              Create Careers
            </LoadingButton>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
