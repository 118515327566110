/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/no-unresolved */

import * as Yup from 'yup';
import MaterialReactTable from 'material-react-table';
import toast, { Toaster } from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { RemoveRedEye } from '@mui/icons-material';
import {
  listingd,
  Button,
  Container,
  Box,
  Grid,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Card,
} from '@mui/material';
import { FormProvider, RHFTextField } from '../../components/hook-form';
import { PATH_DASHBOARD } from '../../routes/paths';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// import { getProducts } from '../../redux/Weedowl/Weedowlproduct';
import axiosInstance from '../../utils/axios';
import Transferlisting from './TransferCar';
import baseUrl from '../../contexts/Baseurl';

export default function Services() {
  const { enqueueSnackbar } = useSnackbar();
  const NewSubSchema = Yup.object().shape({
    link: Yup.string().required('link is required'),
  });
  const storedUser = JSON.parse(localStorage.getItem('currentuser'));
  // const isadmin = storedUser.role === 'admin';
  const checkadminorsubadminmanager = storedUser?.role === 'admin' || storedUser?.role === 'subadmin_manager';

  const deletecondition =
    storedUser.permissions?.some((e) => e?.name === 'delete_listing') && storedUser?.role === 'subadmin';
  const editcondition =
    storedUser.permissions?.some((e) => e?.name === 'update_listing') && storedUser?.role === 'subadmin';
  const addcondition =
    storedUser.permissions?.some((e) => e?.name === 'add_listing') && storedUser?.role === 'subadmin';
  const Allcondition =
    storedUser.permissions?.some((e) => e?.name === 'all_listing') && storedUser?.role === 'subadmin';
  const getlisting = storedUser.permissions?.some((e) => e?.name === 'get_listing') && storedUser?.role === 'subadmin';
  const featured = storedUser.permissions?.some((e) => e?.name === 'featured') && storedUser?.role === 'subadmin';
  const Viewlink = storedUser.permissions?.some((e) => e?.name === 'view_link') && storedUser?.role === 'subadmin';
  const Viewlisting =
    storedUser.permissions?.some((e) => e?.name === 'view_listing') && storedUser?.role === 'subadmin';
  const videopubished =
    storedUser.permissions?.some((e) => e?.name === 'video_pubished') && storedUser?.role === 'subadmin';
  const transfercon =
    storedUser.permissions?.some((e) => e?.name === 'transfer_listing') && storedUser?.role === 'subadmin';
  const ChangeStatuslisting =
    storedUser.permissions?.some((e) => e?.name === 'change_status_listing') && storedUser?.role === 'subadmin';

  // const { id } = useParams();
  const { state } = useLocation();
  const [Data, setdata] = useState([]);
  const { themeStretch } = useSettings();
  const [videoUrl, setVideoUrl] = useState('');
  const [datas, setdatas] = useState('');
  const [datasone, setdatasone] = useState('');

  const [loader, setloader] = useState(false);
  const [open, setOpen] = useState(false);
  const [openone, setOpenone] = useState(false);
  const navigate = useNavigate();
  const defaultValues = useMemo(
    () => ({
      link: datas?.link || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datas?.link]
  );
  const GetYear = () => {
    setloader(true);
    axiosInstance
      .get(!state ? '/listing' : `/listing?user_id=${state}`)
      .then((response) => {
        if (response?.data) {
          setdata(response?.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        setloader(true);
        console.error(error);
      });
  };
  useEffect(() => {
    GetYear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClickOpen = (url, original) => {
    setVideoUrl(url);
    setdatas(original);
    setOpen(true);
  };

  const handleApproved = async (id) => {
    await axiosInstance
      .get(`/listing-status-change/${id}`)
      .then((res) => {
        if (res.data?.status === true) {
          toast.success(res?.data?.message);
          GetYear();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClickOpenone = (original) => {
    setdatasone(original);
    setOpenone(true);
  };
  const handleCloseone = () => {
    setOpenone(false);
  };
  const handleFeature = async (id) => {
    await axiosInstance
      .get(`/listing-feature-status-change/${id}`)
      .then((res) => {
        console.log(res, 'res');
        if (res.data?.status === true) {
          toast.success(res?.data?.message);
          GetYear();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const HandleVerify = async (id) => {
    await axiosInstance
      .get(`/listing-link-status-change/${id}`)
      .then((res) => {
        console.log(res, 'res');
        if (res.data?.status === true) {
          toast.success(res?.data?.message);
          GetYear();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,

    formState: { isSubmitting, errors },
  } = methods;
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 50,
      },

      {
        accessorKey: 'title',
        header: 'title',
        size: 10,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        size: 10,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 10,
        Cell: ({ row }) => (
          // eslint-disable-next-line prefer-template
          <p> {row?.original?.description?.slice(0, 25) + '...'}</p>
        ),
      },
      {
        accessorKey: 'Listing BY',
        header: 'Listing By',
        size: 10,
        Cell: ({ row }) => (
          // eslint-disable-next-line prefer-template
          <p> {row?.original?.host?.first_name + ' ' + row?.original?.host?.last_name}</p>
        ),
      },
      {
        accessorKey: 'created_at',
        header: 'TimeStamp',
        size: 10,
      },
      // {
      //   accessorKey: 'active_by',
      //   header: 'Active By',
      //   size: 10,
      // },
      {
        accessorKey: 'city',
        header: 'City',
        size: 10,
      },

      {
        accessorKey: 'feature',
        header: 'Featured',
        size: 10,
        Cell: ({ row }) =>
          row?.original?.is_featured === 1 ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
              onClick={() => {
                handleFeature(row?.original?.id, 0);
              }}
            >
              {/* {row.original} */}
              {/* {console.log(row)} */}
              Featured
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
              onClick={() => {
                handleFeature(row?.original?.id, 1);
              }}
            >
              not featured
            </Button>
          ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 10,
        Cell: ({ row }) =>
          row?.original?.is_active === 1 ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
              onClick={() => {
                handleApproved(row?.original?.id, 0);
              }}
            >
              {/* {row.original} */}
              {/* {console.log(row)} */}
              Active
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
              onClick={() => {
                handleApproved(row?.original?.id, 1);
              }}
            >
              Inactive
            </Button>
          ),
      },
      {
        accessorKey: 'vediopub',
        header: 'Vedio Publish',
        size: 10,
        Cell: ({ row }) =>
          row?.original?.is_link_publish === 1 ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
              onClick={() => {
                HandleVerify(row?.original?.id, 0);
              }}
            >
              Publish
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
              onClick={() => {
                HandleVerify(row?.original?.id, 1);
              }}
            >
              Non-Publish
            </Button>
          ),
      },
      {
        accessorKey: 'action',
        header: 'Action',
        size: 100,
        Cell: ({ row }) => {
          // eslint-disable-next-line prefer-destructuring
          const id = row.original.id;

          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              {(Viewlisting || Allcondition || checkadminorsubadminmanager) && (
                <Tooltip arrow placement="left" title="View Listings">
                  <IconButton onClick={() => navigate(`/dashboard/viewlisting/${id}`, { state: row?.original })}>
                    <RemoveRedEye />
                  </IconButton>
                </Tooltip>
              )}
              {(Viewlink || Allcondition || checkadminorsubadminmanager) && (
                <Tooltip arrow placement="left" title="View Vedio">
                  <IconButton onClick={() => handleClickOpen(row?.original?.link, row?.original)}>
                    <RemoveRedEye />
                  </IconButton>
                </Tooltip>
              )}
              {(transfercon || Allcondition || checkadminorsubadminmanager) && (
                <Tooltip arrow placement="left" title="Listing Transfer">
                  <IconButton onClick={() => handleClickOpenone(row?.original)}>
                    <MoveUpIcon />
                  </IconButton>
                </Tooltip>
              )}
              {(editcondition || Allcondition || checkadminorsubadminmanager) && (
                <Tooltip arrow placement="left" title="Edit">
                  <IconButton onClick={() => navigate(`/dashboard/editlisting/${id}`, { state: row?.original })}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {(deletecondition || Allcondition || checkadminorsubadminmanager) && (
                <Tooltip arrow placement="right" title="Delete">
                  <IconButton color="error" onClick={() => handledelete(id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handledelete = async (id) => {
    await axiosInstance
      .delete(`/listing/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Deleted Succesfully');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  // eslint-disable-next-line arrow-body-style
  const transformDataForCSV = (data) => {
    return data.map((item) => ({
      id: item.id,
      title: item.title,
      price: item.price,
      description: item.description,
      created_at: item.created_at,
      city: item.city,
      feature: item.is_featured === 1 ? 'Featured' : 'Not Featured',
      status: item.is_active === 1 ? 'Active' : 'Inactive',
    }));
  };

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const handleExportRows = (rows) => {
    const rowData = transformDataForCSV(rows.map((row) => row.original));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csvData = transformDataForCSV(Data);
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
  };
  const onSubmit = async (data) => {
    const Datas = new FormData();
    Datas.append('link', data?.link);
    Datas.append('city', datas?.city);
    Datas.append('type', datas?.type);
    Datas.append('country', datas?.country);
    Datas.append('description', datas?.description);
    Datas.append('district', datas?.district);
    Datas.append('lease_term', datas?.lease_term);
    Datas.append('price', datas?.price);
    Datas.append('region', datas?.region);
    Datas.append('title', datas?.title);
    // Handle submission logic here
    try {
      await axios
        .post(`${baseUrl}/listing/${datas.id}`, Datas, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'X-Http-Method-Override': 'PUT',
          },
        })
        .then((response) => {
          if (response?.data?.status === true) {
            enqueueSnackbar('Listing Updated Successfully');
            reset();
            setOpen(false);
            navigate(PATH_DASHBOARD.listing.listing);
          }
        });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };
  useEffect(() => {
    if (videoUrl) {
      reset(defaultValues);
    }
  }, [videoUrl, reset, defaultValues]);
  return (
    <>
      <Page title="Listings">
        <Toaster position="top-right" reverseOrder={false} />
        <Container maxWidth={themeStretch ? false : 24}>
          {(Allcondition || getlisting || checkadminorsubadminmanager) && (
            <HeaderBreadcrumbs
              heading="Listings"
              links={[
                { name: 'Dashboard', href: '/dashboard/analytics' },
                {
                  name: 'Listings',
                },
              ]}
              action={
                (addcondition || checkadminorsubadminmanager || Allcondition) && (
                  <Button
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    component={RouterLink}
                    to={PATH_DASHBOARD.listing.addlisting}
                  >
                    New Listing
                  </Button>
                )
              }
            />
          )}

          <Card style={{ display: getlisting || checkadminorsubadminmanager || Allcondition ? 'block' : 'none' }}>
            <MaterialReactTable
              columns={columns}
              enableRowSelection
              state={{
                showSkeletons: loader,
                columnVisibility: {
                  // eslint-disable-next-line no-unneeded-ternary
                  status: ChangeStatuslisting ? true : checkadminorsubadminmanager || Allcondition ? true : false,
                  // eslint-disable-next-line no-unneeded-ternary
                  feature: featured || checkadminorsubadminmanager || Allcondition ? true : false,
                  // eslint-disable-next-line no-unneeded-ternary
                  vediopub: videopubished || checkadminorsubadminmanager || Allcondition ? true : false,
                },
              }}
              data={Data}
              positionToolbarAlertBanner={'bottom'}
              muiTableBodyRowProps={({ row }) => ({
                onClick: row.getToggleSelectedHandler(),
                sx: { cursor: 'pointer' },
              })}
              renderTopToolbarCustomActions={({ table, row }) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    gap: '10px',
                    justifyContent: 'flex-center',
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<FileDownloadIcon />}
                    // component={RouterLink}
                    onClick={handleExportData}
                    // to={''}
                  >
                    Export Data
                  </Button>
                  <Button
                    variant="contained"
                    disabled={table?.getRowModel()?.rows?.length === 0}
                    startIcon={<FileDownloadIcon />}
                    // component={RouterLink}
                    disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                    onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                    // to={''}
                  >
                    Export Selected Rows
                  </Button>
                </Box>
              )}
            />
          </Card>
        </Container>
      </Page>
      <Dialog open={open} onClose={handleClose}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <RHFTextField name="link" label="Enter Link" InputLabelProps={{ shrink: true }} />
            {/* <div dangerouslySetInnerHTML={{ __html: videoUrl }} /> */}
          </DialogContent>
          <DialogActions>
            <Grid item xs={6} md={6}>
              <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                Edit Link
              </LoadingButton>
            </Grid>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
      <Transferlisting open={openone} handleClose={handleCloseone} Datas={datasone} />
    </>
  );
}
