/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { useEffect, useMemo, useState } from 'react';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import toast, { Toaster } from 'react-hot-toast';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router';
import { Button, Grid } from '@mui/material';

import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import { PATH_DASHBOARD } from '../../../routes/paths';
import axiosInstance from '../../../utils/axios';

export default function About() {
  const [dataone, setdataone] = useState([]);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    navigate(PATH_DASHBOARD.company.addabout);
  };

  const Website = () => {
    axiosInstance
      .get(`/about`)
      .then((response) => {
        if (response.data.status === true) {
          const arr = [];
          arr.push(response.data.data);
          setdataone(arr);
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        toast.error(error?.message);
        console.error(error);
      });
  };
  useEffect(() => {
    Website();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'id',
        size: 10,
      },

      {
        accessorKey: 'text_one',
        header: 'Presenting Your Property',
        size: 10,
      },
      {
        accessorKey: 'text_two',
        header: 'Renting or Selling',
        size: 10,
      },
      {
        accessorKey: 'text_three',
        header: 'Property Exchange',
        size: 10,
      },
      {
        accessorKey: 'text_four',
        header: 'Buying a Property',
        size: 10,
      },

      {
        accessorKey: 'text_five',
        header: 'Buying a Property',
        size: 10,
        Cell: ({ row }) => <div dangerouslySetInnerHTML={{ __html: row?.original?.text_five.slice(0, 20) }} />,
      },

      {
        accessorKey: 'image',
        header: 'Image',
        size: 20,
        Cell: ({ row }) => (
          // console.log(`${url}/storage/${row?.original?.image}`)
          // eslint-disable-next-line react/jsx-no-target-blank
          <img src={`${row?.original?.image}`} alt="Hello" />
        ),
      },
    ],
    []
  );

  // ----------------HandleUpdate-----------------------
  // const handleEdit = (data) => {
  //   navigate(`/dashboard/editabout/${data?.id}`, { state: data });
  // };

  return (
    <>
      <Grid container>
        <Toaster position="top-right" reverseOrder={false} />
        <Grid item xs={12} md={12}>
          <HeaderBreadcrumbs
            heading="About"
            links={[{ name: '', href: '' }]}
            action={
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
                About
              </Button>
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <MaterialReactTable columns={columns} data={dataone} />
        </Grid>
      </Grid>
    </>
  );
}
