/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable dot-notation */
/* eslint-disable react/button-has-type */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ImageUploading from 'react-images-uploading';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Grid,
  Stack,
  Container,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  MenuItem,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import axios from '../../utils/axios';
import { PATH_DASHBOARD } from '../../routes/paths';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { FormProvider, RHFTextField, RHFSelect } from '../../components/hook-form';
import {  GetData } from './Amenities';
import RHFDescription from '../../components/hook-form/RHFDescription';
import axiosInstance from '../../utils/axios';
import { Availability, RentPeriod } from './Datas';

export default function AddListing() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // const token = localStorage.getItem('accessToken');
  const [types, settype] = useState([]);
  const [feature, setfeature] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedCheckboxes((prevSelected) =>
      checked ? [...prevSelected, value] : prevSelected.filter((item) => item !== value)
    );
  };

  const NewProviderSchema = Yup.object().shape({
    title: Yup.string().required('Property Title is required'),
    price: Yup.string().required('Price is required'),
    description: Yup.string().required('Property Description is required'),
    type: Yup.string().required('Type is required'),
    country: Yup.string().required('Country is required'),
    region: Yup.string().required('Region is required'),
    city: Yup.string().required('City is required'),
    rent_period: Yup.string().required('rent_period is required'),
    availability: Yup.string().required('availability is required'),
    no_of_bathroom: Yup.string().required('no_of_bathroom is required'),
    garage: Yup.string().required('garage is required'),
    no_of_bedroom: Yup.string().required('no_of_bedroom is required'),
    suburb: Yup.string(),
    district: Yup.string().required('district is required'),
    address: Yup.string(),
    lease_term: Yup.string().required('Lease Term is required'),
  });

  const defaultValues = useMemo(
    () => ({
      title: '',
      description: '',
      price: '',
      lease_term: '',
      type: '',
      availability: '',
      rent_period: '',
      garage: '',
      no_of_bathroom: '',
      no_of_bedroom: '',
      country: '',
      region: '',
      address: '',
      city: '',
      suburb: '',
      district: '',
    }),
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [images, setImages] = useState([]);
  const maxNumber = 69;
  const onChange = (imageList) => {
    setImages(imageList);
  };

  const methods = useForm({
    resolver: yupResolver(NewProviderSchema),
    defaultValues,
  });
  const GetAllAdmin = () => {
    axiosInstance
      .get('/type')
      .then((response) => {
        if (response.data.data) {
          // console.log(response?.data);
          settype(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, []);
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const Data = new FormData();

      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          Data.append(key, data[key]);
        }
      }

      images?.map((item, index) => {
        Data.append(`images[${index}]`, item?.file);
      });

      await axiosInstance.post(`listing`, Data).then((response) => {
        if (response?.data?.status === true) {
          enqueueSnackbar('Listing Add Successfully');
          reset();
          navigate(PATH_DASHBOARD.listing.listing);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };
  useEffect(() => {
    GetData().then((e) => {
      setfeature(e?.data?.data);
    });
    // GetDataone().then((e) => {
    //   settype(e?.data?.data);
    // });
    // Gettitle().then((e) => {
    //   settitle(e?.data?.data);
    // });
  }, []);
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs heading="Add Listing" links={[{ name: '', href: '' }]} />

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} mt={5}>
            <Card sx={{ p: 3 }}>
              <Typography variant="h4" sx={{ pb: 2 }}>
                Information
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                <Grid item xs={12}>
                  <RHFTextField name="title" label="Title" />
                </Grid>

                <Grid item xs={12}>
                  <RHFSelect
                    name={'type'}
                    label="Listing Type"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {
                      // eslint-disable-next-line arrow-body-style
                      types?.map((e) => {
                        return (
                          <MenuItem key={e.value} value={e.name}>
                            {e.name}
                          </MenuItem>
                        );
                      })
                    }
                  </RHFSelect>

                  {/* </RHFSelect> */}
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="price" label="Price" type="number" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="country" label="Country" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="region" label="Region" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="district" label="District" />
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect
                    name={'availability'}
                    label="Availability"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {
                      // eslint-disable-next-line arrow-body-style
                      Availability?.map((e) => {
                        return (
                          <MenuItem key={e.value} value={e.name}>
                            {e.name}
                          </MenuItem>
                        );
                      })
                    }
                  </RHFSelect>

                  {/* </RHFSelect> */}
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect
                    name={'rent_period'}
                    label="Rent Period"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {
                      // eslint-disable-next-line arrow-body-style
                      RentPeriod?.map((e) => {
                        return (
                          <MenuItem key={e.value} value={e.name}>
                            {e.name}
                          </MenuItem>
                        );
                      })
                    }
                  </RHFSelect>

                  {/* </RHFSelect> */}
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="city" label="City" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="garage" label="garage" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="no_of_bedroom" label="no_of_bedroom" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="no_of_bathroom" label="no_of_bathroom" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="suburb" label="Suburb" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="address" label="Street And LandMark (Opitional)" />
                </Grid>

                <Grid item xs={12}>
                  <RHFDescription name="lease_term" label="Lease Term " multiline rows={3} />
                </Grid>
                <Grid item xs={12}>
                  <RHFDescription name="description" label="Description" multiline rows={3} />
                </Grid>
              </Box>
              <Typography variant="h4" sx={{ pb: 2, mt: 3 }}>
                Media
              </Typography>

              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  marginTop: 4,
                  alignItems: 'center',
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
                  {({ imageList, onImageUpload, isDragging, dragProps }) => (
                    <Box
                      sx={{
                        position: 'relative',
                        '& button': {
                          border: 'none',
                          background: isDragging ? '#2e3f6e' : 'none',
                          backgroundSize: 'cover',
                          backgroundColor: '#2e3f6e',
                          width: '100%',
                          height: 120,
                          color: isDragging ? 'white' : 'white',
                        },
                      }}
                    >
                      <Button onClick={onImageUpload} {...dragProps} fullWidth>
                        Click or Drop here <CloudUploadIcon className="ms-2" />
                      </Button>
                      <div className="d-flex flex-wrap justify-content-around align-items-center">
                        {imageList?.map((image, index) => (
                          <div key={index} className="image-item mt-3">
                            <img
                              src={image['data_url']}
                              alt=""
                              width="100"
                              height="100"
                              style={{ objectFit: 'cover', borderRadius: '50%', border: '1px solid #2e3f6e' }}
                            />
                          </div>
                        ))}
                      </div>
                    </Box>
                  )}
                </ImageUploading>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                }}
              >
                <h2 style={{ marginTop: '20px', marginBottom: '0px' }}>Aminities</h2>
                <Grid item xs={12}>
                  {feature?.map((option) => (
                    <FormControlLabel
                      style={{ width: '220px' }}
                      key={option}
                      control={
                        <Checkbox
                          checked={selectedCheckboxes.includes(option?.name)}
                          onChange={handleCheckboxChange}
                          value={option?.name}
                        />
                      }
                      label={option?.name}
                    />
                  ))}
                </Grid>
              </Box>
              {/* <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                }}
              >
                <h2 style={{ marginTop: '20px', marginBottom: '0px' }}>Facility</h2>
                <Grid item xs={12}>
                  {Facility?.map((option) => (
                    <FormControlLabel
                      style={{ width: '220px' }}
                      key={option}
                      control={
                        <Checkbox
                          checked={selectedCheckboxesOne.includes(option?.name)}
                          onChange={handleCheckboxChangeOne}
                          value={option?.name}
                        />
                      }
                      label={option?.name}
                    />
                  ))}
                </Grid>
              </Box> */}

              <div className="d-flex justify-content-between">
                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Add Listing
                  </LoadingButton>
                </Stack>
              </div>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
}
