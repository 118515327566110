/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import {
  Button,
  Grid,
  Dialog,
  MenuItem,
  DialogActions,
  DialogContent,
  //   DialogTitle,
  //   TextField,
} from '@mui/material';
import { PATH_DASHBOARD } from '../../routes/paths';
import { FormProvider, RHFSelect } from '../../components/hook-form';
import axiosInstance from '../../utils/axios';

export default function TransferCar({  open, Datas, handleClose }) {
    const carId = Datas?.id
  const { enqueueSnackbar } = useSnackbar();
  const NewSubSchema = Yup.object().shape({
    host_id: Yup.string().required('User is required'),
  });
  const defaultValues = useMemo(
    () => ({
      host_id: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [user, setuser] = useState([]);
  const GetAllAdmin = () => {
    axiosInstance
      .get('/user')
      .then((response) => {
        if (response.data.data) {
          // console.log(response?.data);
          setuser(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, []);
  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });
  const navigate = useNavigate();
  const {
    handleSubmit,
    reset,

    formState: { isSubmitting,  },
  } = methods;
  const onSubmit = async (data) => {
    try {
        const formData = new FormData();
        formData.append('host_id', data?.host_id);
        formData.append('listing_id', carId);
        await axiosInstance.post('/listing/transfer', formData).then((response) => {
          if (response.data?.message) {
            enqueueSnackbar(response.data?.message, { variant: 'success' });
            reset();
            navigate(PATH_DASHBOARD.listing.listing);
            handleClose();
          }
        });
      } catch (error) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ '& .MuiDialog-paper': { width: '500px', overflowX: 'hidden' } }} // Using the sx prop
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <RHFSelect
            size="large"
            InputLabelProps={{ shrink: true }}
            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            name="host_id"
            label="Select User"
          >
            {user.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.first_name}
              </MenuItem>
            ))}
          </RHFSelect>
        </DialogContent>
        <DialogActions>
          <Grid item xs={6} md={6}>
            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              Transfer Listing
            </LoadingButton>
          </Grid>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
