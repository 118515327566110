// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    website: path(ROOTS_DASHBOARD, '/website'),
    subadmin: path(ROOTS_DASHBOARD, '/subadmin'),
    demo: path(ROOTS_DASHBOARD, '/demo'),
    viewpermission: (id) => path(ROOTS_DASHBOARD, `/subadmin/Viewpermission${id}`),
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  company: {
    aboutus: path(ROOTS_DASHBOARD, '/about-us'),
    editabout: (id) => path(ROOTS_DASHBOARD, `/editabout/${id}`),
    addabout: path(ROOTS_DASHBOARD, `/addabout`),
    addrentalguides: path(ROOTS_DASHBOARD, `/addrentalguides`),
    addblog: path(ROOTS_DASHBOARD, `/addblog`),
    Careers: path(ROOTS_DASHBOARD, '/careers'),
    Blog: path(ROOTS_DASHBOARD, '/blogs'),
    Rental: path(ROOTS_DASHBOARD, '/rental-guides'),
    Our_contact: path(ROOTS_DASHBOARD, '/our-contact'),
  },
  Support: {
    report: path(ROOTS_DASHBOARD, '/report'),
    FAQs: path(ROOTS_DASHBOARD, '/faqs'),
    addfaq: path(ROOTS_DASHBOARD, '/addfaq'),
    Terms: path(ROOTS_DASHBOARD, '/terms'),
    Privacy: path(ROOTS_DASHBOARD, '/privacy-policy'),
    Advertise: path(ROOTS_DASHBOARD, '/advertise'),
  },
  subadminmanger: {
    subadminmanger: path(ROOTS_DASHBOARD, '/subadminm'),
    permission: path(ROOTS_DASHBOARD, '/permission'),
    addpermission: path(ROOTS_DASHBOARD, '/addpermission'),
    addsubadminmanger: path(ROOTS_DASHBOARD, '/addsubadminm'),
  },
  weedowl: {
    root: path(ROOTS_DASHBOARD, '/weedowl'),
    product: path(ROOTS_DASHBOARD, '/weedowl/product'),
    new: path(ROOTS_DASHBOARD, '/weedowl/singleproduct/add'),
    edit: (id) => path(ROOTS_DASHBOARD, `/weedowl/singleproduct/edit${id}`),
    category: path(ROOTS_DASHBOARD, '/weedowl/category'),
    user: path(ROOTS_DASHBOARD, '/weedowl/user'),
    order: path(ROOTS_DASHBOARD, '/weedowl/order'),
    offer: path(ROOTS_DASHBOARD, '/weedowl/offer'),
    offerproduct: (id) => path(ROOTS_DASHBOARD, `/weedowl/offer/product${id}`),
    newsletter: path(ROOTS_DASHBOARD, '/weedowl/newsletter'),
    addnewsletter: path(ROOTS_DASHBOARD, '/weedowl/newsletter/add'),
    newsletterEdit: (id) => path(ROOTS_DASHBOARD, `/weedowl/newsletter/edit${id}`),
    // edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  listing: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    listing: path(ROOTS_DASHBOARD, '/services'),
    addlisting: path(ROOTS_DASHBOARD, '/addlisting'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (id) => path(ROOTS_DASHBOARD, `/editlisting/${id}`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  location: {
    loaction: path(ROOTS_DASHBOARD, '/location'),
    addlocation: path(ROOTS_DASHBOARD, '/addlocation'),
    editlocation: (id) => path(ROOTS_DASHBOARD, `/editlocation/${id}`),
  },
  feature: {
    feature: path(ROOTS_DASHBOARD, '/feature'),
    addfeature: path(ROOTS_DASHBOARD, '/addfeature'),
    editfeature: (id) => path(ROOTS_DASHBOARD, `/editfeature/${id}`),
  },
  type: {
    type: path(ROOTS_DASHBOARD, '/type'),
    addtype: path(ROOTS_DASHBOARD, '/addtype'),
    edittype: (id) => path(ROOTS_DASHBOARD, `/edittype/${id}`),
  },
  title: {
    title: path(ROOTS_DASHBOARD, '/title'),
    addtitle: path(ROOTS_DASHBOARD, '/addtitle'),
    edittitle: (id) => path(ROOTS_DASHBOARD, `/edittitle/${id}`),
  },
  advertisement: {
    advertisement: path(ROOTS_DASHBOARD, '/advertisement'),
    addadvertisement: path(ROOTS_DASHBOARD, '/addadvertisement'),
    editadvertisement: (id) => path(ROOTS_DASHBOARD, `/editadvertisement/${id}`),
  },
  logs: {
    calllogs: path(ROOTS_DASHBOARD, '/call_logs'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
