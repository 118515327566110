/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Card, Grid, Stack, Container, Box, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { useForm } from 'react-hook-form';
// import { PATH_DASHBOARD } from '../../../routes/paths';
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
// import Typography from 'src/theme/overrides/Typography';
import { fData } from '../../../utils/formatNumber';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import baseUrl from '../../../contexts/Baseurl';
// import { BaseUrl1 } from '..BaseUrl/BaseUrl';

export default function EditHost() {
  const { id } = useParams();
  console.log(id);
  const { enqueueSnackbar } = useSnackbar();
  const NewSubSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required'),
    phone: Yup.string()
    .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required'),
    file: Yup.mixed().required('Image is required'),
  });
const BaseUrl1 ='' 
  const { state } = useLocation();
  const defaultValues = useMemo(
    () => ({
      first_name: state?.first_name || '',
      last_name: state?.last_name||'',
      email: state?.email ||'',
      phone: state?.phone ||'',
      file: BaseUrl1.BaseUrl1+state?.image ||'',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });
  const navigate = useNavigate();
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = methods;
  const token = localStorage.getItem('accessToken');
  const onSubmit = async (data) => {
    console.log( data );
    try {
      const dorm = new FormData();
      dorm.append('first_name', data?.first_name);
      dorm.append('email', data?.email);
      dorm.append('last_name', data?.last_name);
      dorm.append('phone', data?.phone);
      if(typeof data?.file ==='string'){
        dorm.append('image', '');
      }

      else if(typeof data?.file === 'object') {
        dorm.append('image', data?.file);
      }
      
      
      await axios.post(`${baseUrl}host/${id}`, dorm,{
        headers: {
          'Accept': 'application/json',
          'Authorization':`Bearer ${token}`,
          'X-Http-Method-Override': 'put',
        }
      }).then((response) => {
        console.log(response?.data, '=========');
        if (response.data?.message ) {
          enqueueSnackbar(response.data?.message);
          reset();
          navigate('/dashboard/host');
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
      variant: 'error',
      });
      console.error(error);
    }
  };
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'file',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Update Host" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Host Profile Image
                    </Typography>
                    <RHFUploadAvatar
                      name="file"
                      accept="image/*"
                      maxSize={3145728}
                      onDrop={handleDrop}
                      helperText={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png, *.gif
                          <br /> max size of {fData(3145728)}
                        </Typography>
                      }
                    />
                  </Box>
                  <RHFTextField name="first_name" label="First Name" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="last_name" label="Last Name" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="email" type="email" label="Email" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="phone" type="number" label="Phone Number" InputLabelProps={{ shrink: true }} />
                  {/* <RHFTextField name="file" type="file"  label="Image" InputLabelProps={{ shrink: true }} /> */}

                  <Grid item xs={6} md={6}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Update Host
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
