/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Container,
  Box,
  Typography,
  MenuItem,
  Select,
  Divider,
  // Radio,
  //   CardHeader,
  Grid,
  Card,
  CardContent,
  Stack,
  Tooltip,
  IconButton,
} from '@mui/material'; // Import Typography for text
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

import axiosInstance from '../../../utils/axios';
import { useParams } from 'react-router';

function ViewLising() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const GetData = () => {
    axiosInstance
      .get(`/listing/${id}`)
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res?.data?.listing);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    GetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="View Listing">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="View Listing" links={[{ name: '', href: '' }]} action={<></>} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ my: 3 }}>
              <CardContent>
                <Stack spacing={2}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body" sx={{ color: 'text.secondary' }}>
                      Title
                    </Typography>
                    <Typography variant="body1">{data?.title}</Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body" sx={{ color: 'text.secondary' }}>
                      type
                    </Typography>
                    <Typography variant="body1">{data?.type}</Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body" sx={{ color: 'text.secondary' }}>
                      price
                    </Typography>
                    <Typography variant="body1">{data?.city}</Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body" sx={{ color: 'text.secondary' }}>
                      description
                    </Typography>
                    <Typography variant="body1">{data?.city}</Typography>
                  </Stack>
                  <Divider />

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body" sx={{ color: 'text.secondary' }}>
                      country
                    </Typography>
                    <Typography variant="body1">{data?.country}</Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body" sx={{ color: 'text.secondary' }}>
                      Region
                    </Typography>
                    <Typography variant="body1">{data?.region}</Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body" sx={{ color: 'text.secondary' }}>
                      district
                    </Typography>
                    <Typography variant="body1">{data?.district}</Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body" sx={{ color: 'text.secondary' }}>
                      City
                    </Typography>
                    <Typography variant="body1">{data?.city}</Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body" sx={{ color: 'text.secondary' }}>
                      Suburb
                    </Typography>
                    <Typography variant="body1">{data?.suburb}</Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body" sx={{ color: 'text.secondary' }}>
                      Streeat And Landmark
                    </Typography>
                    <Typography variant="body1">{data?.address}</Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body" sx={{ color: 'text.secondary' }}>
                      Lease Term
                    </Typography>
                    <Typography variant="body1">{data?.lease_term}</Typography>
                  </Stack>

                  <Divider />
                  {
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        my: 3,
                      }}
                    >
                      {data?.listing_images?.map((img, index) => (
                        <Tooltip key={index} title={`Image ${index + 1}`}>
                          <img alt="Image" src={img?.image} style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        </Tooltip>
                      ))}
                    </Box>
                  }
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default ViewLising;
