/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, Typography, Box } from '@mui/material';
import axios from '../../utils/axios';
import { fData } from '../../utils/formatNumber';
import Label from '../../components/Label';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../components/hook-form';

// import BaseUrl1 from '../../BaseUrl/BaseUrl';

export default function CompanyProfile() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = JSON.parse(localStorage.getItem('currentuser'));

  const NewCompanySchema = Yup.object().shape({
    name: Yup.string().required('First Name is required'),
    email: Yup.string().required('Email is required').email(),
    lname: Yup.string().required('Last Name is required'),
    // address: Yup.string().required('Address is required'),
    password: Yup.string().required('Password is required'),
    image: Yup.mixed().required('Company Thumbnail is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentUser?.first_name || '',
      email: currentUser?.email || '',
      // address: currentUser?.address || '',
      lname: currentUser?.last_name || '',
      phone: currentUser?.phone || '',
      password: '',
      image: currentUser?.image || '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewCompanySchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const OnSubmit = async (data) => {
    // console.log(data, 'call');
    try {
      const c_User = new FormData();
      c_User.append('first_name', data?.name);
      c_User.append('email', data?.email);
      c_User.append('last_name', data?.lname);
      c_User.append('password', data?.password);
      c_User.append('phone', data?.phone);
      // c_User.append('_method', 'PUT');

      if (typeof data?.image === 'string') {
        c_User.append('image', '');
      } else if (typeof data?.image === 'object') {
        c_User.append('image', data?.image);
      }

      await axios.post(`/profile/update`, c_User).then((response) => {
        if (response?.data?.message === 'User Profile Successfully Updated') {
          localStorage.setItem('currentuser', JSON.stringify(response.data?.user));
          enqueueSnackbar('Profile Updated Successfully');
          if (currentUser?.role === 'subadmin') {
            navigate('/dashboard/users');
          } else {
            navigate('/dashboard/analytics');
          }
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };

  // const handlePasswordKeyPress = (event) => {
  //   if (event.key === 'Enter') {
  //     OnSubmit(methods.getValues()); // Call the onSubmit function
  //   }
  // };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <Container maxWidth="lg">
      <HeaderBreadcrumbs heading="Profile" links={[{ name: '', href: '' }]} />
      <FormProvider methods={methods} onSubmit={handleSubmit(OnSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3 }}>
              <Box sx={{ mb: 5 }}>
                <Label
                  color={currentUser?.is_verified === 1 ? 'success' : 'error'}
                  sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
                >
                  {currentUser?.is_verified === 1 ? 'Active' : 'De-Active'}
                </Label>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                  {`${currentUser?.role !== 'admin' ? 'Sub-Admin' : 'Admin'} Profile Image`}
                </Typography>
                <RHFUploadAvatar
                  name="image"
                  accept="image/*"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <RHFTextField name="name" label="Name" InputLabelProps={{ shrink: true }} />
                <RHFTextField name="lname" label="Last Name" InputLabelProps={{ shrink: true }} />
                <RHFTextField name="email" label="Email" InputLabelProps={{ shrink: true }} />
                <RHFTextField name="phone" label="Phone Number" InputLabelProps={{ shrink: true }} />
                <RHFTextField name="password" label="Password" InputLabelProps={{ shrink: true }} />
              </Box>
              {/* <Grid item xs={12} md={12} sx={{ pt: 2 }}>
                <div>
                  <RHFDescription
                    name="address"
                    label="Address"
                    onKeyPress={handlePasswordKeyPress}
                    multiline
                    rows={4}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </Grid> */}

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Update Profile
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
}
