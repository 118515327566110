/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
// import Select from 'react-select';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Card, Grid, Stack, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

import axios from 'axios';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import baseUrl from '../../../contexts/Baseurl';
// import { amenities } from '../Amenities';
// import axiosInstance from '../../../utils/axios';

export default function EditFeatures() {
  const { id } = useParams();
  const token = localStorage.getItem('accessToken');
  // const [countries, setCountries] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const NewSubSchema = Yup.object().shape({
    name: Yup.string().required('Feature is required'),
  });

  const { state } = useLocation();
  const defaultValues = useMemo(
    () => ({
      name: state?.name || '',
      // Default values
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const navigate = useNavigate();
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('name', values?.name);

      await axios
        .post(`${baseUrl}feature/${id}`, formData, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'X-Http-Method-Override': 'put',
          },
        })
        .then((response) => {
          if (response.data?.message) {
            enqueueSnackbar(response.data?.message, { variant: 'success' });
            reset();
            navigate(PATH_DASHBOARD.feature.feature);
          }
        });
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Edit Feature" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <RHFTextField name="name" label="Edit Features" InputLabelProps={{ shrink: true }} />
                  {/* 
                  <RHFSelect
                    name="name"
                    label="Select Feature"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {
                      amenities.map((amenity) => (
                        <MenuItem key={amenity.name} value={amenity.name}>
                          {amenity.name}
                        </MenuItem>
                      ))
                    }
               
                  </RHFSelect> */}
                  <Grid item xs={12}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting}
                      // onClick={onSubmit}
                    >
                      Edit Feature
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
