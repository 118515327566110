/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { useSnackbar } from 'notistack';
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import axiosInstance from '../../../utils/axios';
import { setSession } from '../../../utils/jwt';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function LoginFormSubm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: 'admin@gmail.com',
    password: '12345678',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,

    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      setLoader(true);

      const companyData = new FormData();
      companyData.append('email', data.email);
      companyData.append('password', data.password);
      companyData.append('role', 'subadmin_manager');
      const response = await axiosInstance.post('login', companyData);
      console.log(response.data?.data);

      if (response.data?.data?.accessToken) {
        setSession(response.data?.data?.accessToken);
        localStorage.setItem('currentuser', JSON.stringify(response.data?.data?.user));
        enqueueSnackbar('Subadmin Login Successful', { variant: 'success' });
        navigate('/dashboard/subadmin');
        window?.location?.reload()

        reset(); // Reset form on error
      } else {
        // Handle other error cases from API response
        enqueueSnackbar('Unknown error occurred', { variant: 'error' });
      }
    } catch (err) {
      // Handle errors from axios or unexpected errors
      enqueueSnackbar('An error occurred', { variant: 'error' });
    } finally {
      setLoader(false); // Set loader to false after API call is completed
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
