import React, { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { Card, Grid, Stack, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import axiosInstance from '../../../utils/axios';

export default function AddTitle() {
  // const [countries, setCountries] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const NewSubSchema = Yup.object().shape({
    name: Yup.string().required('Title is required'),

  });

  // const { state } = useLocation();
  const defaultValues = useMemo(
    () => ({
      name: '',
      // Default values
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const navigate = useNavigate();
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('name', values?.name);
      await axiosInstance.post('/title', formData).then((response) => {
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message, { variant: 'success' });
          reset();
          navigate(PATH_DASHBOARD.title.title);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Add Title" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <RHFTextField name="name" label="Add Title" InputLabelProps={{ shrink: true }} />


                  <Grid item xs={12}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting}
                    >
                      Add Title
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
