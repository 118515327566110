/* eslint-disable no-plusplus */
/* eslint-disable block-scoped-var */
/* eslint-disable no-redeclare */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */

import React, { useMemo, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router';
import { Card, Grid, Stack, Container, MenuItem, InputAdornment, IconButton } from '@mui/material'; // Added MenuItem for Select
import { FormProvider, RHFTextField, RHFSelect } from '../../components/hook-form';
import Iconify from '../../components/Iconify';
import axiosInstance from '../../utils/axios';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

export default function Addcategory() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordone, setShowPasswordone] = useState(false);
  const navigate = useNavigate();
  const [Data, setdata] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const data = [];
  const year = [];
  for (var i = 1; i <= 60; i++) {
    data.push({
      id: i,
      name: `week ${i}`,
    });
  }
  for (var i = Data?.startYear; i <= Data?.currentYear; i++) {
    year.push({
      // id: i,
      name: `${i}`,
    });
  }
  const NewSubSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Password confirmation is required'),
    service: Yup.string().required('Service selection is required'),
  });

  const defaultValues = useMemo(
    () => ({
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      service: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const { id } = useParams();
  const idone = localStorage.getItem('e_id');
  const onSubmit = async (data) => {
    console.log(data);
    // Handle submission logic here
    try {
      const dorm = new FormData();
      dorm.append('date', data?.dateOfOccurrence);
      dorm.append('employee_id', idone);
      dorm.append('week', data?.week);
      dorm.append('year', data?.year);
      dorm.append('team_lead', data?.teamLeader);
      dorm.append('status', data?.status);
      dorm.append('counseling_severity', data?.counselingSeverity);
      dorm.append('statement_problem', data?.statementOfTheProblem);
      dorm.append('prior_discussion_summary', data?.priorDiscussionSummary);
      dorm.append('summary_corrective_action_discussed', data?.summaryOfCorrectiveAction);
      dorm.append('corrective_behavior', data?.consequences);
      dorm.append('associate_issues', data?.associateName);
      dorm.append('file[0]', data?.file);
      dorm.append('_method', 'PUT');

      //  console.log(user?.id);

      await axiosInstance.post(`/admin/employeeCounseling/${id}`, dorm).then((response) => {
        console.log(response?.data, '=========');
        if (response.data?.message === 'Counseling updated Successfully') {
          enqueueSnackbar('Post update SuccessFully');
          // reset();
          navigate('/dashboard/consuling');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };
  const GetYear = () => {
    axiosInstance
      .get('/admin/years')
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          // console.log(response?.data);
          setdata(response?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetYear();
  }, []);

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Add Category" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <RHFTextField name="firstname" label="First Name" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="lastname" label="Last Name" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="email" label="Email" InputLabelProps={{ shrink: true }} />
                  <RHFSelect
                    name="service"
                    label="Select Service"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    <MenuItem value="">Select Incident Type</MenuItem>
                    <MenuItem value="Accident">Accident</MenuItem>
                    <MenuItem value="Breakdown">Breakdown</MenuItem>
                    <MenuItem value="Vandalism">Vandalism</MenuItem>
                  </RHFSelect>
                  <RHFTextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <RHFTextField
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showPasswordone ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPasswordone(!showPasswordone)} edge="end">
                            <Iconify icon={showPasswordone ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Grid item xs={6} md={6}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    Add Category
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
