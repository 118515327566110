/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import { useState, useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon, RemoveRedEye } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Button, Container, IconButton, Tooltip } from '@mui/material';
// redux
import { useSnackbar } from 'notistack';
// routes
import toast from 'react-hot-toast';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import axiosInstance from '../../../utils/axios';
// import AccordionUsage from './Accordian';

// ----------------------------------------------------------------------

export default function BannerImage() {
  const storedUser = JSON.parse(localStorage.getItem('currentuser'));
  const { enqueueSnackbar } = useSnackbar();
  const [Data, setdata] = useState([]);
  const [loader, setloader] = useState(false);
  console.log(Data);
  const GetAllAdmin = () => {
    setloader(true);
    axiosInstance
      .get('/image-setting')
      .then((response) => {
        if (response.data.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setloader(false);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, []);
  const isadmin = storedUser.role === 'admin';
  const HandleVerify = async (id) => {
    await axiosInstance
      .get(`/image-setting-status-change/${id}`)
      .then((res) => {
        console.log(res, 'res');
        if (res.data?.status === true) {
          toast.success(res?.data?.message);
          GetAllAdmin();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: 'Home Page Banner',
        header: 'Home Page',
        size: 2,
        Cell: ({ row }) => (
          <img
            src={`${row.original?.image_one}`}
            alt="host"
            style={{ width: '60px', height: '60px', borderRadius: '15px' }}
          />
        ),
      },
      {
        accessorKey: 'Home Page Banner Link',
        header: 'Home Page Link',
        size: 10,
        Cell: ({ row }) => (
          <a href={`${row?.original?.link_one}`} target="_blank" rel="noopener noreferrer">
            {row?.original?.link_one}
          </a>
        ),
      },
      {
        accessorKey: 'delete',
        header: 'Delete',
        size: 10,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '10px', justifyContent: 'center' }}>
            {row?.original?.image_one && (
              <Tooltip arrow placement="right" title="Delete Home Page Image">
                <IconButton color="error" onClick={() => handleDelete('image_one')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 30,
        Cell: ({ row }) =>
          row?.original?.status_one === 1 ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
              onClick={() => {
                HandleVerify('image_one', 0);
              }}
            >
              Active
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
              onClick={() => {
                HandleVerify('image_one', 1);
              }}
            >
              De-Active
            </Button>
          ),
      },
      {
        accessorKey: 'Rooms for Rent Banner Image',
        header: 'Rooms for Rent',
        size: 2,
        Cell: ({ row }) => (
          <img
            src={`${row.original?.image_two}`}
            alt="host"
            style={{ width: '60px', height: '60px', borderRadius: '15px' }}
          />
        ),
      },
      {
        accessorKey: 'Rooms for Rent Banner Link',
        header: 'Rooms for Rent Link',
        size: 10,
        Cell: ({ row }) => (
          <a href={`${row?.original?.link_two}`} target="_blank" rel="noopener noreferrer">
            {row?.original?.link_two}
          </a>
        ),
      },
      {
        accessorKey: 'delete',
        header: 'Delete ',
        size: 10,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '10px', justifyContent: 'center' }}>
            {row?.original?.image_two && (
              <Tooltip arrow placement="right" title="Delete Rooms for Rent Image">
                <IconButton color="error" onClick={() => handleDelete('image_two')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 30,
        Cell: ({ row }) =>
          row?.original?.status_two === 1 ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
              onClick={() => {
                HandleVerify('image_two', 0);
              }}
            >
              Active
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
              onClick={() => {
                HandleVerify('image_two', 1);
              }}
            >
              De-Active
            </Button>
          ),
      },
      {
        accessorKey: 'image_three',
        header: 'Apartments for Rent',
        size: 2,
        Cell: ({ row }) => (
          <img
            src={`${row.original?.image_three}`}
            alt="host"
            style={{ width: '60px', height: '60px', borderRadius: '15px' }}
          />
        ),
      },
      {
        accessorKey: 'link_three',
        header: 'Apartments for Rent Link',
        size: 10,
        Cell: ({ row }) => (
          <a href={`${row?.original?.link_three}`} target="_blank" rel="noopener noreferrer">
            {row?.original?.link_three}
          </a>
        ),
      },
      {
        accessorKey: 'delete',
        header: 'Delete ',
        size: 10,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '10px', justifyContent: 'center' }}>
            {row?.original?.image_three && (
              <Tooltip arrow placement="right" title="Delete Apartments for Rent Image">
                <IconButton color="error" onClick={() => handleDelete('image_three')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 30,
        Cell: ({ row }) =>
          row?.original?.status_three === 1 ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
              onClick={() => {
                HandleVerify('link_three', 0);
              }}
            >
              Active
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
              onClick={() => {
                HandleVerify('link_three', 1);
              }}
            >
              De-Active
            </Button>
          ),
      },
      {
        accessorKey: 'image_four',
        header: 'Houses for Rent',
        size: 2,
        Cell: ({ row }) => (
          <img
            src={`${row.original?.image_four}`}
            alt="host"
            style={{ width: '60px', height: '60px', borderRadius: '15px' }}
          />
        ),
      },
      {
        accessorKey: 'link_four',
        header: 'Houses for Rent Link',
        size: 10,
        Cell: ({ row }) => (
          <a href={`${row?.original?.link_four}`} target="_blank" rel="noopener noreferrer">
            {row?.original?.link_four}
          </a>
        ),
      },
      {
        accessorKey: 'delete',
        header: 'Delete',
        size: 10,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '10px', justifyContent: 'center' }}>
            {row?.original?.image_four && (
              <Tooltip arrow placement="right" title="Delete Houses for Rent Image">
                <IconButton color="error" onClick={() => handleDelete('image_four')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 30,
        Cell: ({ row }) =>
          row?.original?.status_four === 1 ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
              onClick={() => {
                HandleVerify('image_four', 0);
              }}
            >
              Active
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
              onClick={() => {
                HandleVerify('image_four', 1);
              }}
            >
              De-Active
            </Button>
          ),
      },
      // Continue for Search Pages, View Profile, and Logout in a similar pattern
      {
        accessorKey: 'image_five',
        header: 'Search Pages',
        size: 2,
        Cell: ({ row }) => (
          <img
            src={`${row.original?.image_five}`}
            alt="host"
            style={{ width: '60px', height: '60px', borderRadius: '15px' }}
          />
        ),
      },
      {
        accessorKey: 'link_five',
        header: 'Search Pages Link',
        size: 10,
        Cell: ({ row }) => (
          <a href={`${row?.original?.link_five}`} target="_blank" rel="noopener noreferrer">
            {row?.original?.link_five}
          </a>
        ),
      },
      {
        accessorKey: 'delete',
        header: 'Delete',
        size: 10,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '10px', justifyContent: 'center' }}>
            {row?.original?.image_five && (
              <Tooltip arrow placement="right" title="Delete Search Pages Image">
                <IconButton color="error" onClick={() => handleDelete('image_five')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 30,
        Cell: ({ row }) =>
          row?.original?.status_five === 1 ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
              onClick={() => {
                HandleVerify('image_five', 0);
              }}
            >
              Active
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
              onClick={() => {
                HandleVerify('image_five', 1);
              }}
            >
              De-Active
            </Button>
          ),
      },
      {
        accessorKey: 'image_six',
        header: 'Profile View',
        size: 2,
        Cell: ({ row }) => (
          <img
            src={`${row.original?.image_six}`}
            alt="host"
            style={{ width: '60px', height: '60px', borderRadius: '15px' }}
          />
        ),
      },
      {
        accessorKey: 'link_five',
        header: 'Profile View Link',
        size: 10,
        Cell: ({ row }) => (
          <a href={`${row?.original?.link_five}`} target="_blank" rel="noopener noreferrer">
            {row?.original?.link_five}
          </a>
        ),
      },
      {
        accessorKey: 'delete',
        header: 'Delete',
        size: 10,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '10px', justifyContent: 'center' }}>
            {row?.original?.image_six && (
              <Tooltip arrow placement="right" title="Delete Profile Image">
                <IconButton color="error" onClick={() => handleDelete('image_six')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 30,
        Cell: ({ row }) =>
          row?.original?.status_six === 1 ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
              onClick={() => {
                HandleVerify('image_six', 0);
              }}
            >
              Active
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
              onClick={() => {
                HandleVerify('image_six', 1);
              }}
            >
              De-Active
            </Button>
          ),
      },
      {
        accessorKey: 'image_seven',
        header: 'Logout',
        size: 2,
        Cell: ({ row }) => (
          <img
            src={`${row.original?.image_seven}`}
            alt="host"
            style={{ width: '60px', height: '60px', borderRadius: '15px' }}
          />
        ),
      },
      {
        accessorKey: 'link_six',
        header: 'Logout Link',
        size: 10,
        Cell: ({ row }) => (
          <a href={`${row?.original?.link_six}`} target="_blank" rel="noopener noreferrer">
            {row?.original?.link_six}
          </a>
        ),
      },
      {
        accessorKey: 'delete',
        header: 'Logout Image',
        size: 10,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '10px', justifyContent: 'center' }}>
            {row?.original?.image_seven && (
              <Tooltip arrow placement="right" title="Logout Image">
                <IconButton color="error" onClick={() => handleDelete('image_seven')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 30,
        Cell: ({ row }) =>
          row?.original?.status_seven === 1 ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
              onClick={() => {
                HandleVerify('image_seven', 0);
              }}
            >
              Active
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
              onClick={() => {
                HandleVerify('image_seven', 1);
              }}
            >
              De-Active
            </Button>
          ),
      },
    ],
    []
  );

  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.get(`/image-delete/${rowdata}`).then((response) => {
        console.log(response);
        if (response?.data?.message) {
          enqueueSnackbar('Deleted Sucessfully');
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  async function handleDeletes(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.get(`/image-delete/${rowdata}`).then((response) => {
        console.log(response);
        if (response?.data?.message) {
          enqueueSnackbar('Deleted Sucessfully');
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }

  async function handleApproved(id) {
    try {
      await axiosInstance.get(`/user-status-change/${id}`).then((response) => {
        if (response) {
          enqueueSnackbar(response?.data?.message);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }

  return (
    <>
      <Page title="Banner Image">
        <Container maxWidth="lg">
          <HeaderBreadcrumbs
            heading="Banner Image"
            links={[{ name: '', href: '' }]}
            action={
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={'/dashboard/add-banner-image'}
                // to={''}
              >
                Banner Image
              </Button>
            }
          />

          <MaterialReactTable
            columns={columns}
            data={Data}
            // enableRowActions
            state={{
              showSkeletons: loader,
              // columnVisibility: {
              //   verified_by: isadmin,
              // },
            }}

            // positionActionsColumn="last"
          />
        </Container>
      </Page>
      {/* <AccordionUsage/> */}
    </>
  );
}
