
/* eslint-disable no-unused-vars */

/* eslint-disable arrow-body-style */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
import React, { useEffect, useMemo, useState } from 'react';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import toast, { Toaster } from 'react-hot-toast';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate, useParams } from 'react-router';
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Tooltip,
  IconButton,
  Avatar,
} from '@mui/material';

import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import { PATH_DASHBOARD } from '../../../routes/paths';
import axiosInstance from '../../../utils/axios';
// import { useParams } from 'react-router';

export default function Careers() {
  const { id } = useParams();
  const url = 'https://api.dg.dev-iuh.xyz';
  const [data, setdata] = useState([]);
  const [dataone, setdataone] = useState([]);
    const navigate = useNavigate()
  const [uniqeidupdate, setuniqeidupdate] = useState([]);
  const [checkupdate, setcheckupdate] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState('');
  const [description, setdescription] = useState('');
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [image, setimage] = useState('');
  //   api/subadmin/permission/website
  const token = localStorage.getItem('accessToken');
  const handleClickOpen = () => {
    navigate(PATH_DASHBOARD.company.Our_contact)
   
  };



  const Website = () => {
    axiosInstance
      .get(`api/subadmin/about_us`)
      .then((response) => {
        if (response.data.status === true) {
          setdataone(response.data.data);
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        toast.error(error?.message);
        console.error(error);
      });
  };
  useEffect(() => {
    Website();
  }, []);


  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'id',
        size: 10,
      },
      
      {
        accessorKey: 'content',
        header: 'About',
        size: 150,
      },
 
      
      {
        accessorKey: 'action',
        header: 'Action',
        size: 100,
        Cell: ({ row }) => {
          const id = row.original.id;
          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => handleEdit(row.original)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handledelete(id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    []
  );
  // ---------viewpage-------------
  //   const handleviewproduct = (id) => {
  //     navigate(PATH_DASHBOARD.general.viewpermission(id))
  //   };
  //  ------------------handledlt--------------
  const handledelete = (id) => {
    axios
      .delete(`${url}/api/subadmin/about_us/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success('About Deleted Succesfully');
        }
        // console.log(res, 'delete');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // ---------------handlesubmit--------------------
  const HandleSubmit = () => {
    try {
      const datatosend = new FormData();
      datatosend.append('category_id', id);
      datatosend.append('title', title);
      datatosend.append('description', description);
      datatosend.append('image', image);

      axios
        .post(`${url}/api/subadmin/blogs`, datatosend, {
          headers: {
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            setOpen(false);
            toast.success('Blog Added Successfully');
          }
          console.log(res, 'subadmin');
        })
        .catch((err) => {
          setOpen(false);
          console.log(err.Error, 'subadmin');
          //   alert(err);
        });
    } catch (error) {
      console.log(error, 'catch');
    }
  };

  // ----------------HandleUpdate-----------------------
  const handleEdit = (data) => {
    navigate(`/dashboard/editabout/${data?.id}`,{state:data})

  };
 
  return (
    <>
      <Grid container>
        <Toaster position="top-right" reverseOrder={false} />
        <Grid item xs={12} md={12}>
          <HeaderBreadcrumbs
            heading="Blogs"
            links={[{ name: '', href: '' }]}
            action={
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
                Blog 
              </Button>
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <MaterialReactTable columns={columns} data={dataone} />
        </Grid>
      </Grid>
    
    </>
  );
}
