import React, { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { Card, Grid, Stack, Container ,Box, Typography  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField ,RHFUploadAvatar} from '../../../components/hook-form';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import axiosInstance from '../../../utils/axios';
import { fData } from '../../../utils/formatNumber';

export default function Addadvertisement() {
  // const [countries, setCountries] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const NewSubSchema = Yup.object().shape({
    link: Yup.string().required('Link is required'),
    file: Yup.mixed().required('file is required'),
  });

  // const { state } = useLocation();
  const defaultValues = useMemo(
    () => ({
      link: '',
      file: '',
      // Default values
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const navigate = useNavigate();
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('link', values?.link);
      formData.append('image', values?.file);
      await axiosInstance.post('/advertisement', formData).then((response) => {
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message, { variant: 'success' });
          reset();
          navigate(PATH_DASHBOARD.advertisement.advertisement);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'file',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Add Advertisement" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Advertisement Image
                    </Typography>
                    <RHFUploadAvatar
                      name="image"
                      accept="image/*"
                      maxSize={3145728}
                      onDrop={handleDrop}
                      helperText={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png, *.gif
                          <br /> max size of {fData(3145728)}
                        </Typography>
                      }
                    />
                  </Box>
                  <RHFTextField name="link" label="Add Advertisement" InputLabelProps={{ shrink: true }} />

                  <Grid item xs={12}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Add Advertisement
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
