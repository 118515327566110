/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import { useState, useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { Delete as DeleteIcon, RemoveRedEye } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Button, Container, IconButton, Tooltip } from '@mui/material';
// redux
import { useSnackbar } from 'notistack';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import axiosInstance from '../../../utils/axios';
// import AccordionUsage from './Accordian';

// ----------------------------------------------------------------------

export default function Type() {
  const { enqueueSnackbar } = useSnackbar();
  const [Data, setdata] = useState([]);
  const [loader, setloader] = useState(false);

  const GetAllAdmin = () => {
    setloader(true);
    axiosInstance
      .get('/type')
      .then((response) => {
        if (response.data.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setloader(false);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 5,
      },
      {
        accessorKey: 'name',
        header: 'Listing Type',
        size: 10,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(Data);
  }, []);

  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.delete(`/type/${rowdata}`).then((response) => {
        console.log(response);
        if (response?.data?.message) {
          enqueueSnackbar('Deleted Sucessfully');
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(Data);
    download(csvConfig)(csv);
    console.log(csv);
  };
  async function handleApproved(id) {
    try {
      await axiosInstance.get(`/user-status-change/${id}`).then((response) => {
        if (response) {
          enqueueSnackbar(response?.data?.message);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  console.log(Data, '....................');
  return (
    <>
      <Page title="Type">
        <Container maxWidth="lg">
          <HeaderBreadcrumbs
            heading="Type"
            links={[{ name: '', href: '' }]}
            action={
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={'/dashboard/addtype'}
                // to={''}
              >
                Type
              </Button>
            }
          />

          <MaterialReactTable
            columns={columns}
            data={Data}
            enableRowActions
            enableRowOrdering
           
       
            positionToolbarAlertBanner={'bottom'}

            renderTopToolbarCustomActions={({ table, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: '10px',
                  justifyContent: 'flex-center',
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<FileDownloadIcon />}
                  component={RouterLink}
                  onClick={handleExportData}
                  to={''}
                >
                  Export Data
                </Button>
                <Button
                  variant="contained"
                  disabled={table?.getRowModel()?.rows?.length === 0}
                  startIcon={<FileDownloadIcon />}
                  component={RouterLink}
                  disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                  onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                  to={''}
                >
                  Export Selected Rows
                </Button>
              </Box>
            )}
            state={{ showSkeletons: loader }}
            enableRowSelection
                 muiTableBodyRowProps={({ row }) => ({
              onClick: row.getToggleSelectedHandler(),
              sx: { cursor: 'pointer' },
            })}
               positionActionsColumn="last"
            renderRowActions={({ row }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: '10px',
                  justifyContent: 'flex-center',
                }}
              >
                <IconButton
                  color="primary"
                  sx={{
                    border: '1px solid',
                    borderColor: 'primary.main',
                  }}
                  onClick={() => {
                    navigate(`/dashboard/edittype/${row?.original?.id}`, { state: row.original });
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="error"
                  sx={{
                    border: '1px solid',
                    borderColor: 'error.main',
                  }}
                  onClick={() => {
                    handleDelete(row.original.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
         
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  Data.splice(hoveredRow.index, 0, Data.splice(draggingRow.index, 1)[0]);
                  setdata([...Data]);
                  // setloader(true);
                  const formnew = [...Data];

                  const formdata = new FormData();
                  formdata.append('types', JSON.stringify(formnew));
                  axiosInstance
                    .post('/types-drag', formdata)
                    .then((response) => {
                      if (response.data.data) {
                        // console.log(response?.data);
                        setdata(response?.data?.data);
                        // setloader(false);
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                      // setloader(false);
                    });
                }
              },
            })}
          />
        </Container>
      </Page>
      {/* <AccordionUsage/> */}
    </>
  );
}
