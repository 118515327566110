/* eslint-disable no-undef */
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState } from 'react';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../../../components/chart';
import axiosInstance from '../../../../utils/axios';

// ----------------------------------------------------------------------



export default function AnalyticsWebsiteVisits() {
    const [data, setData] = useState([]);
    const [dataone, setDataone] = useState([]);
    console.log(data,'data')
    const GetData = () => {
      axiosInstance
        .get(`/users/total`)
        .then((res) => {
          if (res?.data) {
            const formattedData = res.data.map(item => item.total);
            setData(formattedData);
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const GetDataone = () => {
      axiosInstance
        .get(`/listings/total`)
        .then((res) => {
          if (res?.data) {
            const formattedData = res.data.map(item => item.total);
            setDataone(formattedData);
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    
  useEffect(()=>{
GetData()
GetDataone()
  },[])
  const CHART_DATA = [
    {
      name: 'User',
      type: 'column',
      // eslint-disable-next-line object-shorthand
      data: data,
    },
    {
      name: 'Listings',
      type: 'column',
      data: dataone,
    },
    
  ];
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '14%' } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: [
      '01/01/2024',
      '02/01/2024',
      '03/01/2024',
      '04/01/2024',
      '05/01/2024',
      '06/01/2024',
      '07/01/2024',
      '08/01/2024',
      '09/01/2024',
      '10/01/2024',
      '11/01/2024',
      '12/01/2024',
    ],
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} Entry`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader title="Website Visits" subheader="(+43%) than last year" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
