import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Alert, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from '../../../utils/axios';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const { id } = useParams();
  console.log(id);
  const { enqueueSnackbar } = useSnackbar();
  // const currentUser=JSON.parse(localStorage.getItem('currentuser'))

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),

    token: Yup.string().required('Token is required').min(4, 'Token must be at least 4 characters'),
    password: Yup.string().required('Password is required'),
    cpassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const defaultValues = {
    token: id || '',
    password: '',
    email: '',
    cpassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const forget = new FormData();
      forget.append('email', data?.email);
      forget.append('otp', data?.token);
      forget.append('password', data?.password);
      forget.append('password_confirmation', data?.cpassword);

      await axios
        .post(`/reset`, forget)

        .then((response) => {
          console.log(response);
          if (response?.data?.status === true) {
            enqueueSnackbar(response?.data?.message);
            navigate('/');
          } else {
            enqueueSnackbar(response?.data?.error, {
              variant: 'error',
            });
          }
        });
    } catch (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="Email" />
        <RHFTextField name="token" label="Token" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="cpassword"
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Forget Password
        </LoadingButton>
        <Button fullWidth size="large" component={RouterLink} to="/reset-passsword" sx={{ mt: 1 }}>
          Back
        </Button>
      </Stack>
    </FormProvider>
  );
}
