/* eslint-disable no-unused-expressions */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, Box, Typography } from '@mui/material';
import axios from 'axios';
import ReactQuill from 'react-quill';
// import BaseUrl from '../../BaseUrl/BaseUrl';
import { fData } from '../../../utils/formatNumber';
import baseUrl from '../../../contexts/Baseurl';
// import { BlogSchema } from '../AllSchema/Blog';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import 'react-quill/dist/quill.snow.css';
import { PATH_DASHBOARD } from '../../../routes/paths';

export default function Editblog() {
  const BlogSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    image: Yup.mixed().required('Blog Banner image is required'),
    //   description: Yup.string().required('Description is required'),
  });

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const token = localStorage.getItem('accessToken');
  const [value, setValue] = useState(state?.description);

  const defaultValues = useMemo(
    () => ({
      title: state?.title || '',
      image: state?.image || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(BlogSchema),
    defaultValues,
  });

  const {
    reset,

    handleSubmit,
    setValue: setFormValue,
    formState: { isSubmitting },
  } = methods;
  const { id } = useParams();

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setFormValue(
          'image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setFormValue]
  );

  const OnSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('title', values?.title);
      formData.append('description', value);

      if (typeof values.image === 'string') {
        formData.append('image', '');
      } else if (typeof values.image === 'object') {
        formData.append('image', values.image);
      }

      await axios
        .post(`${baseUrl}blog/${id}`, formData, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'X-Http-Method-Override': 'put',
          },
        })
        .then((response) => {
          if (response.data?.message) {
            enqueueSnackbar(response.data?.message, { variant: 'success' });
            reset();
            navigate(PATH_DASHBOARD.company.Blog);
          }
        });
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };
  const quillRef = useRef(null);

  // const imageHandler = useCallback(() => {
  //   const input = document.createElement('input');
  //   input.setAttribute('type', 'file');
  //   input.setAttribute('accept', 'image/*');
  //   input.setAttribute('multiple', 'true');
  //   input.click();

  //   input.onchange = async () => {
  //     setLoader(true);
  //     const files = Array.from(input.files);
  //     if (files.length > 0) {
  //       const editor = quillRef.current.getEditor();
  //       const range = editor.getSelection();
  //       editor.blur();
  //       const temp = await localStorage.getItem('accessToken');
  //       const config = {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //           Authorization: ` Bearer ${temp}`,
  //         },
  //       };
  //       const uploadPromises = files.map((file) => {
  //         const formData = new FormData();
  //         formData.append('des_image', file);
  //         return axios
  //           .post('https://api.dg.dev-iuh.xyz/api/subadmin/blogImage', formData, config)
  //           .then((result) => result?.data?.des_image)
  //           .catch((error) => {
  //             setLoader(false);
  //             console.error('Error:', error);
  //             return null;
  //           });
  //       });

  //       const urls = await Promise.all(uploadPromises);
  //       urls.forEach((url) => {
  //         if (url) {
  //           setImageUrls((prevUrls) => [...prevUrls, url]);
  //           editor.insertEmbed(range.index, 'image', url);
  //           editor.setSelection(range.index + 1); // Move cursor to the right of the inserted image
  //         }
  //       });

  //       editor.focus();
  //       setLoader(false);
  //       // Restore focus after insertion
  //     }
  //   };
  // }, []);

  return (
    <Container maxWidth="lg">
      <HeaderBreadcrumbs heading="Edit Blog" links={[{ name: '', href: '' }]} />
      <FormProvider methods={methods} onSubmit={handleSubmit(OnSubmit)}>
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                  Blog Image
                </Typography>
                <RHFUploadAvatar
                  name="image"
                  accept="image/*"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />
              </Box>
              <Stack spacing={3} sx={{ py: 3 }}>
                <RHFTextField name="title" label="Title" />

                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  ref={quillRef}
                  modules={{
                    toolbar: {
                      container: [
                        [{ header: '1' }, { header: '2' }, { font: [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                        ['link', 'image', 'video'],
                        ['clean'],
                      ],
                      // handlers: {
                      //   image: imageHandler,
                      // },
                    },
                  }}
                  formats={[
                    'header',
                    'font',
                    'size',
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'blockquote',
                    'list',
                    'bullet',
                    'indent',
                    'link',
                    'image',
                    'video',
                  ]}
                />
              </Stack>
              <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                Edit Blog
              </LoadingButton>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
}
