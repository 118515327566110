/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */

import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DatePicker, LoadingButton } from '@mui/lab';
// import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
import { Card, Grid, Stack, Container, MenuItem, Button, Divider, Box, TextField, Typography } from '@mui/material';
import axios from 'axios';
import axiosInstance from '../../../utils/axios';
import { fData } from '../../../utils/formatNumber';
// import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import { RHFSelect, FormProvider, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import baseUrl from '../../../contexts/Baseurl';
import { PATH_DASHBOARD } from '../../../routes/paths';

export default function Editfaq() {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [Data, setdata] = useState([]);
  const [Device, setDevice] = useState([]);
  const [emplyoee, setemployee] = useState([]);
  const [vehcile, setvehicle] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  // console.log(Data, 'state');
  const token = localStorage.getItem('accessToken');
  const NewSubSchema = Yup.object().shape({
    question: Yup.string().required('question is required'),
    image: Yup.mixed().required(' image is required'),
    answer: Yup.string().required('answer is required'),
    //   description: Yup.string().required('Description is required'),
  });

  const defaultValues = useMemo(
    () => ({
      question: state?.question || '',
      answer: state?.answer || '',
      image: state?.image || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const value = watch();

  console.log(value, 'value');
  console.log(errors, 'errors');

  useEffect(() => {
    if (Data) {
      reset(defaultValues);
    }
  }, [Data, reset, defaultValues]);
  // const idone = localStorage.getItem('e_id');
  const handleAdd = () => {
    console.log(value, 'values');
    if (value.roster.length < 4) {
      setValue('roster', [...value.roster, '']);
    }
  };

  const handleRemove = (index) => {
    // eslint-disable-next-line prefer-const
    let la = value.roster;
    la.splice(index, 1);
    setValue('roster', la);
  };

  const OnSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('question', values?.question);
      formData.append('answer', values?.answer);
      if (typeof values.image === 'string') {
        formData.append('image', '');
      } else if (typeof values.image === 'object') {
        formData.append('image', values.image);
      }
      await axios
        .post(`${baseUrl}faq/${id}`, formData, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'X-Http-Method-Override': 'put',
          },
        })
        .then((response) => {
          if (response.data?.message) {
            enqueueSnackbar(response.data?.message, { variant: 'success' });
            reset();
            navigate(PATH_DASHBOARD.Support.FAQs);
          }
        });
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  const arr = [
    {
      name: 'OnTime',
    },
    {
      name: 'Late',
    },
  ];
  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Update New Faq" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(OnSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Faq Image
                  </Typography>
                  <RHFUploadAvatar
                    name="image"
                    accept="image/*"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 2,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, *.gif
                        <br /> max size of {fData(3145728)}
                      </Typography>
                    }
                  />
                </Box>
                <Stack spacing={1.5}>
                  <Stack spacing={3}>
                    <RHFTextField name={`question`} label="Question" InputLabelProps={{ shrink: true }} />

                    <RHFTextField name={`answer`} label={`Answer`} InputLabelProps={{ shrink: true }} />
                  </Stack>

                  <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
